// Charge la base commune
import './common.js'

// Home Slider
async function getHomeSlider () {
  import(/* webpackChunkName: "homeSlider", webpackPrefetch: true */ './js/components/homeSlider.js').then(() => {
    /* global Waypoint */
    Waypoint.refreshAll()
  })
}

// Offers Search Form
async function getOsForm ($f) {
  import(/* webpackChunkName: "offersSearchForm" */ './js/components/offersSearchForm.js').then(() => {
    Waypoint.refreshAll()
    $f.load(false)
  })
}

// Agencies Search Form
async function getAsForm ($f) {
  import(/* webpackChunkName: "agenciesSearchForm" */ './js/components/agenciesSearchForm.js').then(() => {
    Waypoint.refreshAll()
    $f.load(false)
  })
}

// Offers Autocomplete
async function getOffersAutocomplete ($f) {
  import(/* webpackChunkName: "offersAutocomplete" */ './js/components/offersAutocomplete.js').then(module => {
    module.offersAutocomplete.init($f)
  })
}

// Agency autocomplete
async function getAgenciesAutocomplete ($f) {
  import(/* webpackChunkName: "agenciesAutocomplete" */ './js/components/agenciesAutocomplete.js').then(module => {
    module.agenciesAutocomplete.init($f)
  })
}

// actions principales communes à toutes les pages
$(document).ready(function () {
  // gestion du slider principal
  const $a = $('.home-slider')
  if ($a.length) {
    $a.waypoint(function () {
      getHomeSlider()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion de la recherche d'offres
  const $b = $('form[name="osForm"]')
  if ($b.length) {
    $b.waypoint(function () {
      $b.load()
      getOsForm($b)
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion de la recherche d'agences la plus proche
  const $c = $('form[name="asForm"]')
  if ($c.length) {
    $c.waypoint(function () {
      $c.load()
      getAsForm($c)
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // filtres des offres autocomplete
  $('form[name="osForm"] input[name="location"]').on('click keyup focus', function () {
    $(this).blur().off().attr('disabled', '').val('Veuillez patienter...')
    getOffersAutocomplete($(this).parents('form'))
  })

  // filtres des agences autocomplete
  $('form[name="asForm"] input[name="location"]').on('click keyup focus', function () {
    $(this).blur().off().attr('disabled', '').val('Veuillez patienter...')
    getAgenciesAutocomplete($(this).parents('form'))
  })
})
