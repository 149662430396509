/**
 * Etend une zone de clic vers une page de destination
 * la zone d'extension doit porter la classe .t-click
 * l'url à suivre doit être sur un lien href et porter la classe .t-url
 */
/*
var tClickimclicked = false
export function tClick() {
  $('.t-click').off('click').on('click', function (e) {
    if ( !$(e.target).is('a') ) { // ne s'applique pas sur le clic d'un a.href sinon clic 2 fois sur le lien sur firefox par exemple et c'est problématique avec des target="_blank"
      if ( !tClickimclicked ) {
        var a = $(this).find('a.t-url')
        a.get(0).click()
      }
    }
  })
  $('.t-click a.t-url').off('click').on('click', function (e) {
    tClickimclicked = true
    setTimeout(function() {
      tClickimclicked = false
    }, 100)
  })
}
*/

/**
 * Fonction native jquery qui affiche un loader à un élément en cours de chargement (durant un import dynamique par exemple)
 * @param {boolean} i = true Affiche ou non le loader
 */
$.fn.load = function(i = true) {
  if (i) {
    this.find('.load').stop(true, true).animate({opacity: .5}, 200)
    this.find('input, button, textarea, select').prop('disabled', true)
    $(this.find('.processing')[0]).stop(true, true).show().animate({opacity: 1}, 200)
  } else {
    this.addClass('loaded')
    this.find('.load').stop(true, true).animate({opacity: 1}, 200)
    this.find('input, button, textarea, select').prop('disabled', false)
    $(this.find('.processing')[0]).stop(true, true).animate({opacity: 0}, 200, function() { $(this).hide() })
  }
}

// Wait while GMap API is loading
export function dtectMap(action) {
  if ( $('body').hasClass('maploaded') ) {
    action()
  } else {
    setTimeout(function() {
      dtectMap(action)
    }, 200)
  }
}
